import React, { useEffect } from "react";
import { graphql } from "gatsby";

import { motion } from "framer-motion";
import HTMLReactParser from "html-react-parser";

import { DefaultContainer } from "components/common/Structure";
import CreateHead from "components/createHeader";
import { DotContainer } from "components/common/Structure";
import Footer from "components/footer";
import Colored from "../../components/colored/Colored";

const containerVariants = {
  hidden: {
    opacity: 0,
    y: 0,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: { delay: 0, duration: 1 },
  },
  exit: {
    opacity: 0,
    y: 0,
    transition: { ease: "easeInOut", duration: 1 },
  },
};

const Policy = (props) => {
  //PROPS
  const {
    data: {
      page: {
        policy: { pparagraph },
      },
    },
    setColor,
  } = props;
  //PROPS

  //DEFS
  const lang =
    props.pageContext.translations[0].language.code === "EN" ? "PT" : "EN";
  //DEFS

  useEffect(() => {
    setColor(true);
  }, []);

  return (
    <>
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <DefaultContainer>
          <section className="policy container container--xsmall">
            {pparagraph.map((para, index) => {
              return (
                <div key={index}>
                  <h3>{para.ptitle}</h3>
                  {HTMLReactParser(para.pmessage)}
                </div>
              );
            })}
          </section>
        </DefaultContainer>
        <Footer lang={lang} />
      </motion.div>
    </>
  );
};

export const query = graphql`
  query Policy($id: String) {
    page: wpPage(id: { eq: $id }) {
      policy {
        pparagraph {
          pmessage
          ptitle
        }
      }
    }
  }
`;

export default Policy;
